.privacypolicy {
  width: 100%;
  display: flex;
  justify-content: center;
}
.privacypolicy1 {
  width: 95%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.privacypolicy1 p {
  font-size: 1.8rem;
}
.privacypolicyh2div h2 {
  font-size: 3rem;
  font-weight: 900;
  margin-bottom: 40px;
  margin-top: 40px;
}
.privacypolicyh2divpart1div22 {
  display: flex;
  flex-direction: column;
}
