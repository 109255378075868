.getintouchdivbgmain {
  position: relative;
}
.getintouchdivbg img {
  width: 100%;
  height: 100vh;
}
.getintouchdivbg1 {
  position: absolute;
  top: 0;
  z-index: 1;
  /* background-image: linear-gradient(#f7941d, #fd6234); */
  background-color: #140b01;
  opacity: 1;
  width: 100%;
  height: 100%;
  border-bottom-right-radius: 100%;
}
.getintouchdivbg1formimg img {
  width: 500px;
}
.getintouchdivbg1form {
  position: absolute;
  top: 0;
  z-index: 2;
  border-bottom-right-radius: 100%;
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100%;
  align-items: center;
}
.getintouchdivbg1formchild {
  width: 80%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  place-items: center;
  align-items: center;
  background-color: #fff;
  border-radius: 20px;
  height: 80%;
}
.getintouchdivbg1forminputs {
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.getintouchdivbg1forminputs input,
textarea {
  background-color: #e9e1d9;
  color: #7d776f;
  border-radius: 20px;
  padding: 10px;
  border: none;
  font-size: 1.4rem;
}
textarea {
  font-size: 1.4rem !important;
}
.getintouchdivbg1forminputs2 {
  width: 80%;
}
.getintouchdivbg1forminputs2 h2 {
  color: #372e23;
  font-weight: 600;
}
.getintouchdash {
  border: 2px solid #372e23;
  width: 20%;
}
.getintouchdashh2 {
  margin-bottom: 10%;
}
.getintouchdashh2 h2 {
  font-size: 2.8rem;
}
.getintouchdivbg1forminputsbtn {
  width: 100%;
  text-align: center;
}
.btnsh {
  background-color: #140b01;
  color: #fff;
  border-radius: 20px;
  border: none;
  width: 80%;
  padding: 8px;
  font-size: 1.5rem;
}
.pageupmaindiv {
  display: flex;
  justify-content: flex-end;
  margin-right: 20px;
  cursor: pointer;
  position: absolute;
  bottom: 0;
  z-index: 2;
  width: 100%;
  margin-left: -20px;
}
.pageupmaindiv p {
  color: #140b01;
  font-size: 1.5rem;
  font-weight: 800;
}
.pageupmaindiv span {
  /* background-image: linear-gradient(to right, #ed8211, #ed5d54); */
  background-color: #140b01;
  color: #fff;
  border-radius: 100%;
  padding: 2px;
  text-align: center;
}
.pageupmaindiv span :nth-child(1) {
  margin-top: -4px;
  font-size: 20px;
}
@media screen and (max-width: 750px) {
  .getintouchdivbg1formchild {
    display: flex;
    flex-direction: column;
    padding: 10px;
  }
  .getintouchdivbg1formchild img {
    width: 120px;
    margin-top: 5px;
  }
}
