.bckdivmaindiv {
  position: relative;
}
.bckdiv img {
  width: 100%;
  object-fit: cover;
  height: 100vh;
}
.dlrymvng {
  position: absolute;
  top: 0%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
}
.btn {
  font-size: 1.2rem;
  padding: 10px;
  width: 200px;
  border: 2px solid transparent;
  border-radius: 50px;
  font-size: 1.5rem;
  background-color: #fff;
  color: #ed5d54;
}
.dlrymvngbtns {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  width: 100%;
}
.btnsh {
  background-color: #140b01;
  color: #fff;
  font-size: 1.5rem;
}
.dlrymvngbck {
  display: flex;
  justify-content: center;
}
.dlrymvngh2 {
  width: 100%;
  text-align: center;
}
.dlrymvngh2 h2 {
  font-size: 3rem;
  font-weight: 900;
}
@media screen and (max-width: 600px) {
  .dlrymvngbck {
    overflow: scroll;
  }
  .bckdiv img {
    width: 100%;
    object-fit: cover;
    height: 65vh;
  }
}
