.navbarMain {
  z-index: 9999;
  width: 100%;
  background-color: transparent;
}
.navmaindi22v {
  /* filter: blur(8px); */
  top: 2px;
  /* background-repeat: repeat;
  width: 100% !important;
  background-image: url("../../assets/dot.png");
  position: relative;
  background-size: 12px;
  opacity: 0.2;
  z-index: -1; */
}
.navmaindiv {
  /* position: sticky;
  z-index: 99999999;
  width: 100%;
  background-color: transparent; */
  /* background-repeat: repeat;
  width: 100% !important;
  background-image: url("../../assets/dot.png");
  position: relative;
  background-size: 12px;
  opacity: 0.2; */
  /* z-index: 9; */
}
.img {
  margin-left: 10%;
}
.navlogo {
  width: 200px;
}
.navmaindiv111 {
  background-color: #140b01;
  padding-bottom: 5px;
  padding-top: 5px;
  top: 0;
  display: block;
  border-radius: 50px;
  margin: 20px;
  z-index: 9;
}

.navlink2 {
  border-radius: 100px;
  border: 1px outset raqua;
  background-color: #60941a;
  padding: auto;
  display: flex !important;
  align-items: center !important;
  padding: 4px !important;
  width: 25px;
  height: 25px;
}

.containernavdivafterss {
  width: 200px;
  background-color: #fff;
}
.containernavdivafterss > div {
  border: 0.3px outset rgb(209, 205, 205);
}
.containernavdivafterss p {
  padding: 20px;
  cursor: pointer;
}

.Navlinktext22 {
  color: #000;
  display: flex;
  align-items: center;
  padding: 5px;
  flex-direction: row;
  justify-content: start;
}
.Navlinktext22:hover {
  color: #e56a1c;
}
.navlink2 :nth-child(1) {
  font-size: 16px;
  color: #fff;
}
.brand {
  cursor: pointer;
}
.personafterpshw {
  display: flex;
  flex-direction: column;
  top: 10px;
}
.containernavdivafter {
  display: none;
  position: absolute;
  z-index: 999;
  float: right;
  width: 100%;
  margin-left: -160px;
  margin-top: 25px;
}
.showaccnt {
  display: block;
}
.Navlinktext2 {
  display: flex;
}
.cartdltslength {
  margin-right: 5px;
}
.favoriteIcon {
  color: #e66f27;
}
.img {
  margin-top: -11px;
  width: 212px;
}
.navmaindiv {
  /* background-color: #140b01;
  padding-bottom: 5px;
  padding-top: 5px;
  top: 0;
  display: block;
  border-radius: 50px;
  position: sticky; */
}
.navlink {
  font-size: 1.7rem !important;
  color: #fff !important;
}
.nav2 {
  display: flex;
  flex-direction: row;
  gap: 40px !important;
}
.navlink:hover {
  text-decoration: none !important;
  color: #ed820d !important;
}
.active {
  background-color: red;
}
.navmaindivlogo {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  margin: 0 10px;
}
@media screen and (max-width: 600px) {
  .navmaindi22v {
    /* filter: blur(8px); */
    top: 0px;
  }
  .navmaindiv111 {
    background-color: #140b01;
    padding-bottom: 0;
    padding-top: 0;
    top: 0;
    display: block;
    border-radius: 0%;
    margin: 0;
  }
  .navmaindiv {
    top: 0% !important
    ;
  }
}
