.modalclose {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}
.modalclosebtn {
  cursor: pointer;
}
.dmodal {
  width: 200px;
}
.drminputlablein {
  display: flex;
  flex-direction: column;
}
.drminputlablein label {
  margin-top: 5px;
  margin-bottom: 0px;
  color: #7d776f;
}
.drminpudivmain {
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.drminpudivmainh22 {
  width: 100%;
  text-align: center;
}
.drminpudivmainh22 h2 {
  font-size: 2rem;
}

.drminputlablein input {
  border-radius: 6px;
  background-color: #fff;
  color: #7d776f;
  padding: 3px;
  border: 0.3px solid gray;
  font-size: 1.4rem;
}
.getintouchdivbg1forminputsbtn {
  margin-top: 10px;
}

.btnsh {
  background-color: #140b01;
  color: #fff;
  border-radius: 20px;
  border: none;
  width: 100%;
  padding: 4px;
  font-size: 1.5rem;
}
.drminputlableinrdio {
  display: flex;
  flex-direction: row;
  width: 100%;
}
.lott {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
  width: 100%;
}
