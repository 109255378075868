.OurHeighlightsmaindiv {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 50px;
}
.OurHeighlightsmaindiv1 {
  width: 80%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 50px;
}
.OurHeighlightsmaindivh2 h2 {
  font-size: 3vw;
  font-weight: 900;
}
.OurHeighlightsmaindivcontent {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
.OurHeighlightsmaindivcontent > div {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.OurHeighlightsmaindivcontent p {
  font-size: 1.2vw;
  margin-top: 10px;
  margin-bottom: 10px;
}
.OurHeighlightsmaindivcontent img {
  width: 100px;
}
@media screen and (max-width: 600px) {
  .OurHeighlightsmaindivcontent {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    width: 100%;
  }
  .OurHeighlightsmaindivcontent img {
    width: 100px;
  }
  .OurHeighlightsmaindivcontent p {
    font-size: 1.4rem;
  }
  .OurHeighlightsmaindivh2 h2 {
    font-size: 3rem;
  }
}
