.clientsmaindiv {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;

  position: relative;
  height: 100vh;
  overflow: hidden !important;
}
.clientsmaindiv22 {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.clientsmaindivh1img {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.clientsmaindivh2 {
  margin-bottom: 5%;
}
.clientsmaindivclients {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.clientsmaindivimg {
  margin-bottom: 10%;
}
.clientsmaindivimg img {
  width: 100px;
}
/* .clientsmaindivclients2 img {
  width: 100px;
}
.clientsmaindivclients1 img {
  width: 100px;
} */
.clientsmaindivh2 h2 {
  font-size: 3.2rem;
  font-weight: 900 !important;
}
.clientsmaindivclientsp {
  margin-top: 10%;
}
.clientsmaindivclientsp p {
  font-size: 2rem;
}
.clientsmaindivbccc {
  position: absolute;
  top: 0;
  width: 100%;
  left: 70%;
  overflow: hidden;
}
.clientsmaindivbccc img {
  object-fit: cover;
  -webkit-animation: cog 50s infinite;
  -moz-animation: cog 50s infinite;
  -ms-animation: cog 50s infinite;
  animation: cog 50s infinite;
  -webkit-animation-timing-function: linear;
  -moz-animation-timing-function: linear;
  -ms-animation-timing-function: linear;
  animation-timing-function: linear;
}

@keyframes cog {
  100% {
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
.clientsmaindivclients1 {
  width: 100%;
}
.classNameimg13 {
  width: 30px;
}
.clien1 img {
  width: 100px;
}
@media screen and (max-width: 600px) {
  .clientsmaindiv {
    height: auto;
    text-align: center;
  }
  .clientsmaindivclientsp p {
    font-size: 1.5rem;
  }
  .clientsmaindivh2 h2 {
    font-size: 2.6rem;
    font-weight: 900 !important;
  }
}
