.howitwrksmaindivbg img {
  width: 100%;
  height: 100vh;
  object-fit: cover;
}
.howitwrksmaindivbgmain {
  position: relative;
  margin-bottom: 30px;
}
.howitwrksmaindivbg1 {
  position: absolute;
  top: 0;
  z-index: 1;
  opacity: 0.7;
  width: 100%;
  height: 100%;
}
.howitwrksmaindivcontent {
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: row;
  position: absolute;
  z-index: 3;
  height: 100%;
  top: 0;
}
.howitwrksmaindivcontent1 {
  width: 80%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 20%;
}
.howitwrksmaindivcontent12 {
  width: 100%;
}
.howitwrksmaindivcontent1imgh2 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  gap: 10px;
}
.howitwrksmaindivcontent1imgh2 img {
  width: 120px;
}
.howitwrksmaindivcontent1imgh2 h2 {
  font-size: 2.8rem;
  color: #000;
  font-weight: 900;
  margin-top: 10px;
}
.howitwrksmaindivcontent1imgh2slide img {
  width: 150px;
}
.howitwrksmaindivcontent1imgh2slide {
  /* display: flex !important;
  flex-direction: column !important;
  justify-content: center !important;
  align-items: center !important; */
  display: grid !important;
  grid-template-rows: 1fr 1fr !important;
  text-align: center !important;
  grid-gap: 20px !important;
}
.howitwrksmaindivcontent1imgh2slide h2 {
  font-size: 2rem;
  font-weight: 800;
}
.howitwrksmaindivcontent1imgh2slide p {
  font-size: 1.4rem;
  font-weight: 800;
  color: #000;
}
/* .className1 img {
  width: 40px;
} */
.classNameimg {
  width: 40px;
}
.imagediv {
  display: flex;
  align-items: flex-end;
  justify-content: center;
}

.contentdiv {
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
}
@media screen and (max-width: 850px) {
  .howitwrksmaindivbg img {
    height: 80vh;
  }
  .howitwrksmaindivcontent1imgh2 {
    text-align: center;
  }
  .howitwrksmaindivcontent1imgh2slide img {
    width: 150px;
  }
  .howitwrksmaindivcontent1 {
    gap: 10px;
  }
  .classNameimg {
    width: 30px;
  }
}
