.mcmaindiv {
  display: grid;
  grid-template-columns: 2fr 2fr;
  width: 100%;
  place-items: center;
  height: 85%;
  align-items: flex-start;
}
/* .mcmaindiv3 img {
  width: 300px;
} */
.dcmaindiv {
  /* background-image: linear-gradient(to right, #ed8211, #ed5d54); */
  border-radius: 30px;
  height: 100%;
  margin-top: 0%;
  padding: 20px;
}
.dcmaindiv {
  width: 80%;
}
.mcmaindiv2 {
  background-color: #140b01;
  max-height: 65vh;
  min-height: 65vh;
  min-width: 350px;
  border-radius: 20px;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 10px;
}
.mcmaindiv222 {
  display: flex;
  flex-direction: column;
  align-items: unset;
  gap: 20px;
}
.mcmaindiv2223 p {
  cursor: pointer;
  /* padding: 5px; */
  font-size: 1.7rem;
}
.mcmaindiv2active {
  color: #ed5d54;
  font-size: 2.8rem !important;
  border-left: 4px solid #fff;
}
.mcmaindiv2 p:hover {
  color: #ed5d54;
}
.mcmaindiv4 {
  /* padding: 40px; */
}
.mcmaindiv4 p,
h2 {
  color: #140b01;
}
.mcmaindiv2p {
  color: #e2e0df;
  font-weight: lighter;
}
.mcmaindiv4 p {
  font-size: 1.4rem;
}
.mcmaindiv4 h2 {
  font-size: 2.6rem;
  font-weight: 800;
}
.mcircle {
  border-left: 6px solid rgb(255, 255, 255);
  height: 35px;
  position: absolute;
  left: 50%;
}

.mcmaindiv {
  display: grid;
}
.mcmaindiv4imghpp {
  max-height: 65vh;
  min-height: 65vh;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  text-align: center;
}
.mcmaindiv4imghpp img {
  width: 350px;
}

.mcmaindiv412imgh2slider {
  display: none;
}

@media screen and (max-width: 600px) {
  .mcmaindiv412imgh2slider {
    display: block;
  }
  .mcmaindiv2 {
    display: none;
  }
  .mcmaindiv {
    display: none;
  }
  .mcmaindiv312,
  .mcmaindiv412 {
    display: none;
  }
  .mcmaindiv3,
  .mcmaindiv4 {
    display: none;
  }
  .mcmaindiv412 {
    display: flex;
    flex-direction: row;
    overflow: scroll;
  }
  .mcmaindiv412 img {
    width: 200px;
    height: 200px;
  }
  .mcmaindiv412imgh2 {
    display: flex;
    flex-direction: column;
    width: 200px !important;
    height: 200px !important;
  }
  .mcmaindiv412imgh2 img {
    width: 200px !important;
    height: 200px !important;
  }
}
