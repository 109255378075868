.howitwrkdivvv {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #212121;
  overflow: hidden;
  margin-bottom: 30px;
}
.lotti {
  width: 100vw;
}
.howitwrkdivvvdiv img {
  height: 100vh;
  width: 100vw;
  object-fit: cover;
}
.lottidiv {
  position: absolute;
  top: 0;
  z-index: 2;
  height: 100%;
  width: 100%;
}
.howitwrkdivvvdiv {
  display: flex;
}

.howitwrkdivvvimgmob {
  display: none;
}
.lottidiv {
  display: block;
}
.howitwrkshmattermaindiv2 p,
.howitwrkshmattermaindiv2222 p {
  width: 70%;
}
.howitwrkshmattermaindiv2,
.howitwrkshmattermaindiv2222 {
  display: flex;
  flex-direction: row;
  gap: 20px;
}
.howitwrkshmattermaindiv2222 {
  justify-content: flex-end;
}
.howitwrkshmattermaindiv2222m {
  width: 80%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}
.howitwrkshmattermaindiv2new {
  display: flex;
  width: 100%;
  justify-content: flex-start;
}
.howitwrkshmattermaindiv2new2 {
  display: flex;
  width: 100%;
  justify-content: flex-end;
}
.howitwrkshmattermaindiv img {
  display: block;
}
@media screen and (max-width: 891px) {
  .lottidiv {
    position: absolute;
    top: 0;
    z-index: 2;
    height: auto;
    width: 100%;
  }
  .howitwrkdivvvdiv img {
    height: 100vh;
    width: 230%;
    object-fit: cover;
  }
  .howitwrkdivvvimgmob {
    display: none;
  }
  .lottidiv {
    display: block;
  }
}
@media screen and (max-width: 890px) {
  .howitwrkshmattermaindiv {
    display: flex;
    flex-direction: column;
    padding: 2%;
    justify-content: space-around;
    align-items: center;
    height: 100%;
  }
  .howitwrksh1maindiv {
    display: flex;
    width: 100%;
    text-align: center;
    justify-content: center;
  }
  .howitwrksh1maindiv h1 {
    color: #000;
    font-size: 3rem;
  }
  .howitwrkdivvvdiv {
    display: none;
  }
  .howitwrkdivvvdiv img {
    height: 100vh;
    object-fit: cover;
    width: 230%;
  }
  .howitwrkdivvvimgmob {
  }
  .howitwrkdivvvimgmob {
    display: block;
    position: relative;
    width: 100%;
  }
  .howitwrksmaindivbgmin {
    position: absolute;
    top: 0;
    z-index: 2;
    width: 100%;
    height: 100% !important;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 10px;
  }
  .howitwrksmaindivbgmin h2 {
    color: #000;
  }
  .howitwrksmaindivbgmin p {
    color: #000;
  }

  .lottidiv {
    display: none;
  }

  .howitwrkdivvvimg img {
    width: 230%;
    height: 100vh;
    object-fit: cover;
  }
  .howitwrkdivvvimgdivv {
    display: none !important;
  }
  .howitwrkdivvvimg {
    background-repeat: repeat;
    width: 100% !important;
    height: 100vh !important;
    background-image: url("../../../assets/dottt.png");
    position: relative;
    background-size: 12px;
    opacity: 0.2;
  }
  .howitwrkdivvv {
    background-color: transparent;
  }
}

@media screen and (max-width: 600px) {
  .howitwrksh1maindiv h1 {
    color: #000;
  }
  .howitwrkdivvvdiv {
    display: none;
  }
  .lottidiv {
    display: none;
  }
  .howitwrkdivvvimgmob {
    display: block;
  }
  .howitwrkshmattermaindiv h2 {
    font-size: 1.6rem;
    color: #000;
  }
  .howitwrkshmattermaindiv p {
    font-size: 1.2rem;
    color: #000;
  }
  .howitwrksmaindivbgmin {
    height: auto;
  }
  .howitwrkdivvvimg img {
    width: 230%;
    height: 100vh;
  }
  .howitwrkdivvv {
    margin-top: 0%;
  }
}
@media screen and (max-width: 300px) {
  .howitwrkshmattermaindiv img {
    display: none;
  }
  .howitwrkshmattermaindiv2new {
    display: flex;
    width: 100%;
    justify-content: center;
  }
  .howitwrkshmattermaindiv2new2 {
    display: flex;
    width: 100%;
    justify-content: center;
  }
  .howitwrkshmattermaindiv2,
  .howitwrkshmattermaindiv2222 {
    display: flex;
    flex-direction: row;
    gap: 20px;
    justify-content: center;
  }
}
