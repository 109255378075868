.reviewsmaindiv {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  margin-bottom: 40px;
}
.reviewsmaindiv1 {
  width: 80%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.reviewsmaindivslidermaindivblw {
  width: 90%;
}
.reviewsmaindivslidermaindiv {
  display: flex !important;
  flex-direction: row;
  align-items: center;
  width: 80%;
  margin-left: 5%;
  gap: 20px;
  margin-bottom: 10px;
}
.reviewsmaindivimg img {
  width: 100px;
}
.reviewsmaindivcontent {
  display: flex;
  flex-direction: column;
}
.reviewsmaindivnamecity {
  display: flex;
  flex-direction: row;
}
.reviewsmaindivh2 {
  width: 100%;
  text-align: center;
}
.reviewsmaindivh2 h2 {
  font-size: 3rem;
  margin-bottom: 40px;
}
.reviewsmaindivcontent p {
  font-size: 1.2rem;
}
.reviewsmaindivaoute {
  width: 100%;
}
.classReview {
  width: 50px;
}
.reviewsmaindivimg {
  border-radius: 120px;
  border: 1px solid;
  /* padding: 30px; */
  height: 120px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 11px;
  box-shadow: 0px 3px 6px 1px grey;
}
@media screen and (max-width: 800px) {
  .reviewsmaindivslidermaindiv {
    display: flex !important;
    flex-direction: column;
    align-items: center;
    width: 80% !important;
    margin-left: 10%;
    gap: 20px;
  }
}
@media screen and (max-width: 600px) {
  .reviewsmaindivimg img {
    width: 40px;
  }
  .classReview {
    width: 30px;
  }
}
