.homeemaindiv {
  /* width: 100vw; */
  position: relative;
}
.homee {
  position: absolute;
  top: 10%;
  z-index: 1;
}
.homee img {
  width: 100vw;
  /* height: 50vh; */
}
