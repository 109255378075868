.mcmaindiv {
  display: grid;
  grid-template-columns: 2fr 2fr;
  width: 100%;
  place-items: center;
  align-items: center;
  gap: 20%;
}

.dcmaindiv {
  border-radius: 30px;
  height: 100%;
  margin-top: 0%;
  padding: 20px;
}
.dcmaindiv {
  width: 80%;
}
.mcmaindiv2 {
}
.mcmaindiv img {
  width: 100px;
}
.mcmaindiv222 {
  display: flex;
  flex-direction: column;
  align-items: unset;
  gap: 20px;
  background-color: #140b01;
  max-height: 65vh;
  min-height: 65vh;
  min-width: 340px;
  border-radius: 20px;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  clip-path: polygon(
    93% 0,
    100% 0,
    100% 20%,
    99% 100%,
    80% 100%,
    20% 100%,
    0 99%,
    0 13%
  );
}
.mcmaindiv2223 p {
  cursor: pointer;
  font-size: 1.7rem;
}
.mcmaindiv2active {
  color: #fff !important;
  font-size: 2.2rem !important;
  border-left: 4px solid #fff;
  font-weight: 900;
}
.mcmaindiv2 p:hover {
  color: #ed5d54;
}
.mcmaindiv4 {
  /* margin-top: -2%; */
  height: 100%;
}
.mcmaindiv4 p,
h2 {
  color: #140b01;
}
.mcmaindiv2p {
  color: #939393;
  font-weight: lighter;
}
.mcmaindiv4 p {
  font-size: 1.4rem;
}
.mcmaindiv4 h2 {
  font-size: 2.6rem;
  font-weight: 800;
}
.mcircle {
  border-left: 6px solid rgb(255, 255, 255);
  height: 35px;
  position: absolute;
  left: 50%;
}

.mcmaindiv {
  display: grid;
}
.mcmaindiv4imghpp {
  /* max-height: 65vh; */
  min-height: 45vh;
  display: grid;
  grid-template-rows: 1.5fr 1fr;
  height: 100%;
  align-items: center;
  text-align: center;
}
.mcmaindiv4imghpp img {
  /* width: 500px; */
  width: 100%;
  margin-bottom: 20px;
}

.mcmaindiv412imgh2slider {
  display: none;
}

.mcmaindiviconn {
  position: absolute;
  margin-top: 0;
  margin-left: -3%;
  z-index: 9;
}
@media screen and (max-width: 850px) {
  .dcmaindiv {
    width: 90%;
  }
}
@media screen and (max-width: 900px) {
  .mcmaindiv412imgh2slider {
    display: block;
  }
  .classNameimg1 {
    width: 20px;
  }
  .mcmaindiv2 {
    display: none;
  }
  .mcmaindiv {
    display: none;
  }
  .mcmaindiv312,
  .mcmaindiv412 {
    display: none;
  }
  .mcmaindiv3,
  .mcmaindiv4 {
    display: none;
  }
  .mcmaindiv412 {
    display: flex;
    flex-direction: row;
    overflow: scroll;
  }
  .mcmaindiv412 img {
    width: 200px;
    height: 200px;
  }
  .mcmaindiv412imgh2 {
    display: flex !important;
    flex-direction: column;
    width: 100% !important;
    height: 250px !important;
    align-items: center !important;
    padding: 10px;
  }
  .mcmaindiv412imgh2 img {
    width: 200px !important;
    height: 200px !important;
  }
}
