.whychoosemaindiv {
  overflow: hidden;
  position: relative;
  margin-bottom: 50px;
}
.whychoosemaindivdiv {
  position: relative;
  display: flex;
  justify-content: space-between;
  flex-direction: row;
}
.whychoosemaindivel img {
  width: 400px;
  margin-top: -50%;
}
.whychoosemaindivel2 img {
  width: 150px;
  margin-top: 100%;
}

.whychoosemaindivh2mainn {
  width: 100%;
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  top: 0;
  height: 100%;
}
.whychoosemaindivmain {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  /* margin-top: 5%; */
}
.whychoosemaindivh2,
.whychoosemaindivh222 {
  margin-bottom: 5%;
}
.whychoosemaindivh222 {
  display: flex;
  width: 100%;
}
.whychoosemaindivh222m {
  /* display: flex;
  flex-direction: column;
  justify-content: space-around; */
  display: grid;
  grid-template-rows: 2fr 1fr 2fr;
  width: 100%;
  gap: 20px;
}
.whychoosemaindivh222m p {
  font-size: 1vw;
}
.whychoosemaindivh2 h2,
.whychoosemaindivtakeh2 h2 {
  font-size: 2vw;
  font-weight: 900;
}
.whychoosemaindivh222mpdiv {
  display: flex;
  justify-content: center;
}
.whychoosemaindivh222mpdiv p {
  width: 80%;
}
@media screen and (max-width: 600px) {
  .whychoosemaindivmain {
    margin-top: 5%;
  }
  .whychoosemaindivh222 {
    display: flex;
    flex-direction: column;
  }
  .whychoosemaindivh222m p {
    font-size: 1rem;
  }
  .whychoosemaindivh2 h2,
  .whychoosemaindivtakeh2 h2 {
    font-size: 3rem;
    font-weight: 900;
  }
  .whychoosemaindivh222m h3 {
    font-size: 1.4rem;
  }
  .whychoosemaindivh222m {
    gap: 7px;
  }
  .whychoosemaindivmain {
    margin-top: 0;
  }
  .whychoosemaindiv {
    height: 130vh;
  }
  .reviewsmaindivimg {
    height: 62px;
  }
}
