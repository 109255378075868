.container {
  display: flex;

  background-color: #000;
}

.containerimgimg2 {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  place-items: center;
  padding: 20px;
  align-items: center;
  width: 100%;
}
.containerimg img {
  width: 150px;
}
.container p {
  color: #fff;
  text-decoration: none;
  padding: 10px;
  font-size: 1.4rem;
}
.container a {
  color: #fff;
  text-decoration: none;
  padding: 10px;
  font-size: 1.4rem;
}
.container2 {
  width: 100%;
}
.containerimg2 {
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: end;
}
/* .containerimg2 a,
.containerimg2 p {
  color: #fff;
  text-decoration: none;
  padding: 10px;
  font-size: 0.8vw;
} */
.containerimg2 img {
  width: 200px;
}
/* .containerimg2 a,
.containerimg2 p {
  font-size: 1.4rem;
} */
.containerimg12 p {
  color: #fff;
}
.containerimg p {
  margin-top: 10px;
  /* color: #fff; */
}
.containerimg12map {
  display: flex;
  flex-direction: row;
  gap: 5px;
  align-items: center;
}
.containerimg12map img {
  width: 20px;
}

.containerimg {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
/* .containerimg {
  display: block;
} */
.containerimgimg {
  display: none;
}
.mobileCopyright {
  display: none;
}
@media screen and (max-width: 600px) {
  .container {
    display: grid;
    grid-template-rows: 1fr 3fr;
    background-color: #000;
  }
  .containerimgimg {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .containerimg2 img {
    width: 150px;
  }
  .containerimg {
    display: none;
  }
  .containerimgimg2 {
    display: flex;
    flex-direction: column-reverse;
    gap: 20px;
  }
  .containerimgimg img {
    width: 70%;
  }
  .containerimg2 a {
    font-size: 1.6rem;
  }
  .containerimg2 p {
    font-size: 1.6rem;
  }

  .containerimg12 {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 55%;
  }
  .containerimg2 {
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: flex-start;
  }
  .mobileCopyright {
    display: block;
    text-align: center;
  }
}
