.btn {
  font-size: 1.2rem;
  padding: 10px;
  width: 200px;
  border: 1px solid #fff;
  font-size: 1.5rem;
  color: #000;

  border-radius: 31px;
  background: #ffffff;
  /* box-shadow: -5px -5px 10px #e6e6e6, 5px 5px 10px #ffffff; */
}
.introtruckdiv {
  position: relative;
  z-index: 2;
}
.introtruckdivimgcontent {
  position: absolute;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  align-items: center;
  top: 0;
  width: 99%;
  text-align: center;
  gap: 20px;
}
.introtruckdivimgcontent h2 {
  color: #fff;
  font-size: 4vw;
}
.introtruckdivimgcontent p {
  color: #fff;
  font-size: 1vw;
}
.introtruckdivimgcontent span {
  color: #fad03d;
  margin-left: 10px;
}
.introtruckdivimg img {
  width: 100%;
  object-fit: cover;
}
.introtruckdivmainnnnn {
  margin-top: -120px;
}
.introtruckdivimgcontent img {
  width: 150px;
  margin-bottom: 10px;
  margin-right: 15px;
}
@media screen and (max-width: 600px) {
  .introtruckdivimg {
    /* width: 10px; */
  }
  .introtruckdivimgcontent h2 {
    font-size: 3rem;
  }
  .introtruckdivimgcontent p {
    font-size: 1rem;
  }
  .introtruckdivimg img {
    width: 100%;
    object-fit: cover;
    height: 50vh;
  }
}
