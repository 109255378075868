.driverbtns {
  width: 100%;
  display: flex;
  justify-content: center;
  overflow: hidden;
}
.driverbtnsimgdiv {
  width: auto;
  position: relative;
}
.driverbtnsimgdiv2 {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.driverbtnsimgdiv2imgp,
.driverbtnsimgdiv2imgpp2 {
  text-align: center;
  cursor: pointer;
}
.driverbtnsimgdiv2imgp img,
.driverbtnsimgdiv2imgpp2 img {
  width: 50px;
}
.driverbtnsimgdiv2imgp p,
.driverbtnsimgdiv2imgpp2 p {
  font-size: 1.6vw;
  font-weight: 400;
  margin-bottom: 0;
}
.driverbtnsimgdivimg img {
  width: 500px;

  object-fit: cover;
  -webkit-animation: cog 50s infinite;
  -moz-animation: cog 50s infinite;
  -ms-animation: cog 50s infinite;
  animation: cog 50s infinite;
  -webkit-animation-timing-function: linear;
  -moz-animation-timing-function: linear;
  -ms-animation-timing-function: linear;
  animation-timing-function: linear;
}
@keyframes cog {
  100% {
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
.dashmaindiv {
  width: 100%;
  top: 58%;
  position: absolute;
  height: 100px;
  animation: mymove 5s;
}
.dash {
  position: absolute;
  left: 0%;
  top: 100%;
  width: 25%;
  height: 2px;
  background-color: #000;
  transition: all 0.3s ease;
}

.dash1 {
  left: 0%;
}

.dash2 {
  left: 75%;
}

/* @keyframes mymove {
  from {
    left: 0%;
  }
  to {
    left: 70%;
  }
} */
.driverbtnsimgdiv2imgp,
.driverbtnsimgdiv2imgpp2 {
  display: inline;
  text-align: center;
}
.driverbtnsimgdiv2imgp:target ~ hr {
  margin-left: 0%;
}
.driverbtnsimgdiv2imgpp2:target ~ hr {
  margin-left: 25%;
}

hr {
  height: 0.25rem;
  width: 25%;
  margin: 0;
  background: tomato;
  border: none;
  transition: 0.3s ease-in-out;
}

@media screen and (max-width: 600px) {
  .driverbtnsimgdivimg img {
    width: 100%;
  }
  .driverbtnsimgdiv2imgp img,
  .driverbtnsimgdiv2imgpp2 img {
    width: 40px;
  }
  .driverbtnsimgdiv2imgp p,
  .driverbtnsimgdiv2imgpp2 p {
    font-size: 1rem;
    font-weight: 900;
  }
  .driverbtnsimgdiv2 {
    /* margin-top: 20px; */
  }
  .dashmaindiv {
    margin-top: 20px;
  }
  .dash {
    border: 1px solid #000;
    width: 20%;
    height: 2px;
    background-color: #000;
    margin-left: 5px;
  }
}
