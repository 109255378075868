.bckdiv {
  position: relative;
  z-index: 2;
  overflow: hidden;
}
.bckdivmaindiv {
  /* height: 100vh; */
  position: relative;
}
.bckdiv img {
  width: 100vw;
  height: 100vh;
  object-fit: cover;
}
.textandbckgrd {
  position: absolute;
  display: grid;
  grid-template-columns: 2fr 2fr;
  place-items: center;
  width: 100%;
  top: 2%;
  z-index: 2;
  align-items: flex-start;
}

.textandbckgrd h1 {
  font-size: 4vw;
  font-weight: 800;
}
.textandbckgrd p {
  font-size: 1.5vw;
  font-weight: 400;
  margin-top: 4%;
}

.textandbckgrdimgdiv {
  position: relative;
  width: 500px;
}
.mobimg {
  transition: opacity 1s ease-out;
  width: 400px;
  object-fit: cover;
}
.mobimg2 {
  width: 100%;
}
.mobimg3 {
  width: 208px;
  border-radius: 30px;
  object-fit: cover;
}
.textandbckgrdimgdiv222 {
  width: 200px;
  position: absolute;
  left: 20%;
  top: 7%;
}
.bckdivbg {
  display: block;
}
.bckdivbgm {
  display: none;
}
.textandbckgrdhpmaindiv {
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  /* align-items: center; */
  flex-direction: column;
  top: -15%;
  position: relative;
  left: 5%;
}
@media screen and (max-width: 890px) {
  .bckdivbg {
    display: none;
  }
  .bckdivbgm {
    display: none;
  }
  .mobimg {
    width: 370px;
  }
  .textandbckgrdhpmaindiv {
    margin-top: 20%;
  }
  .mobimg3 {
    width: 186px;
  }
}

@media screen and (max-width: 890px) {
  .bckdiv {
    width: 100%;
    height: 90vh;
    object-fit: cover;
  }
  .textandbckgrdhpmaindiv {
    margin-top: 0%;
  }
  .bckdivbg,
  .bckdivbgm {
    display: none !important;
  }
  /* .bckdivbgdot {
    width: 20px !important;
    height: 20px !important;
  } */
  .bckdiv {
    /* background-repeat: repeat;
    width: 100% !important;
    height: 100vh !important;
    background-image: url("../../../assets/dot.png");
    position: relative;
    background-size: 12px;
    opacity: 0.2; */
    /* z-index: 9; */
  }
  .mobimg {
    transition: opacity 1s ease-out;
    width: 300px;
  }
  .textandbckgrdimgdiv222sml {
    display: flex;
    justify-content: center;
  }
  .textandbckgrdimgdiv {
    position: relative;
    width: auto;
  }
  .mobimg3 {
    /* width: fit-content; */
    border-radius: 30px;
  }
  .textandbckgrd {
    top: 2%;
  }
  .bckdivbg {
    display: block;
  }
  .bckdivbgm {
    display: none;
  }
  .textandbckgrdhpmaindiv {
    margin-bottom: 0;
  }
  .mobimg {
    /* width: 350px; */
  }
  .textandbckgrd {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 70vh;
  }

  .textandbckgrd h1 {
    font-size: 3rem;
    font-weight: 800;
  }
  .textandbckgrd p {
    font-size: 2.5vw;
    font-weight: 400;
  }

  .textandbckgrdimgdiv222 {
    position: absolute;
    left: 13%;
    top: 6%;
    width: 228px;
  }
  .mobimg3,
  .mobimg2 {
    border-radius: 40px;
  }
  .textandbckgrdhpmaindiv2 p {
    font-size: 1.4rem;
  }
  .mobimg3 {
    width: 157px;
    border-radius: 30px;
  }
  .textandbckgrdhpmaindiv {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    left: 0;
    top: 0;
  }
}
