.fandqmindiv {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 20px;
  padding: 10px;
}
.fandqmindiv2 {
  width: 90%;
}
.fandqmindiv2h2 h2 {
  font-size: 2rem;
  font-weight: 900;
}
.fandqmindiv2p p {
  font-size: 1.6rem;
}
.fandqmindivh2 {
  width: 100%;
  text-align: center;
  justify-content: center;
}
.fandqmindivh2 h2 {
  font-size: 3rem;
  font-weight: 900;
}
