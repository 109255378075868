.aboutusmaindiv {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.aboutusmaindiv2 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 80%;
  gap: 50px;
}
.aboutusmaindivh2 h2 {
  font-size: 3vw;
  font-weight: 900;
}
.aboutusmaindivcontent span {
  color: #000;
}
.aboutusmaindivcontent p {
  font-size: 1.3vw;
  color: #808080;
  margin-bottom: 3%;
}

@media screen and (max-width: 600px) {
  .aboutusmaindivimg img {
    width: 250px;
  }
  .aboutusmaindivcontent p {
    font-size: 1.4rem;
  }
  .aboutusmaindivh2 h2 {
    font-size: 3rem;
    font-weight: 900;
  }
}
