.evfltimgh2mainn {
  width: 100%;
  margin-bottom: 50px;
}
.evfltimgh2 {
  display: flex;
  width: 100%;
  justify-content: center;
}
.evfltimgh2mg10201mobi {
  display: none;
}

.evfltimgh2mainn111 {
  width: 100%;
}
.evfltimgh2mg1231 {
  width: 100%;
}
.evfltslider {
  width: 95%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-direction: row;
  flex-wrap: wrap;
  height: 100%;
}
.evfltslider2 {
  width: 500px;
  border-radius: 20px;
}
.classNameimg {
  width: 20px;
  /* background-color: red; */
}
.classNameimgc {
  color: #000;
  font-size: 2.4rem;
}
.evfltimgh2mg10111 {
  width: 500px;
}
.evfltimgh2mg102012 img {
  border-radius: 20px;
}
.evfltimgh2 h2 {
  font-size: 3rem;
}
.evfltimgh2mg10111 h4 {
  font-size: 2rem;
  margin-bottom: 20px;
}
.evfltimgh2mg10111 p {
  font-size: 1.4rem;
}
.evfltimgh2mg10111 span img {
  width: 30px;
  margin-right: 10px;
}
.evfltimgh2ev img {
  width: 20px;
  margin-bottom: 2px;
}
.evfltimgh2ev {
  color: #94c11f;
}
.evfltimgh2 {
  margin-bottom: 50px;
}

@media screen and (max-width: 850px) {
  .evfltslider {
    width: 100%;
    padding: 10px;
  }
  .evfltimgh2mg10111 {
  }
  .classNameimg {
    width: 20px !important;
  }
  .evfltslider2 {
    width: 400px;
  }
  .evfltslider2 img {
    width: 400px;
  }
  .evfltimgh2 h2 {
    font-size: 2.4rem;
  }
  .evfltimgh2mg10111 h4 {
    font-size: 1.6rem;
    margin-bottom: 20px;
  }
  .evfltimgh2mg10111 p {
    font-size: 1rem;
  }
}

@media screen and (max-width: 500px) {
  .evfltslider {
    width: 100%;
    padding: 10px;
  }
  .evfltimgh2mg10111 {
  }
  .classNameimg {
    width: 20px !important;
  }
  .evfltslider2 {
    width: 300px;
  }
  .evfltslider2 img {
    width: 300px;
  }
  .evfltimgh2 h2 {
    font-size: 2.4rem;
  }
  .evfltimgh2mg10111 h4 {
    font-size: 1.6rem;
    margin-bottom: 20px;
  }
  .evfltimgh2mg10111 p {
    font-size: 1rem;
  }
}
